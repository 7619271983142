import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/informix";
import {Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        The SQLAPI++ Library requires Informix CLI version 2.x or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        Any valid Informix connection string
    </p>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const OutputParameters = () => (
    <p>
        In Informix stored procedure can return zero or more values or sets of values through a result set. After
        calling <ApiLink>SACommand::Execute</ApiLink>, use {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '} loop to
        retrieve these values.
    </p>
);

const Page = () => (
    <ServerGuide server="Informix"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={OutputParameters}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="Informix"
                                                                      nativeCommand="SQLCancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="Informix"
                                                                        cppHeader="infAPI.h"
                                                                        apiClass="infAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="Informix"
                                                                                handleClass="infConnectionHandles"
                                                                                cppHeader="infAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="Informix"
                                                                          handleClass="infCommandHandles"
                                                                          cppHeader="infAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="Informix"/>}
    />
);

export default Page;
